@font-face {
    font-family: 'Titillium';
    src: local(Titillium Regular), url(/public/fonts/TitilliumWeb-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Titillium';
    src: local(Titillium SemiBold), url(/public/fonts/TitilliumWeb-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'Titillium';
    src: local(Titillium SemiBold), url(/public/fonts/TitilliumWeb-Bold.ttf);
    font-weight: 700;
}