.dataTables_length select {
    margin: 0 5px;
}

.disable-div {
    pointer-events: none;
    opacity: 0.3;
}

.unpaid_amount {
    display: none;
}

.login-aside {
    background-color: #ADD0D4 !important;
}

span.green{
    color: #2E8F96;
}

a .fal {
    color: #2E8F96;
}

.red-row > td, .red-row > td > div.delivery-time-datatable {
    color: #FF647C !important;
}

.red-row i {
    filter: invert(62%) sepia(25%) saturate(5163%) hue-rotate(312deg) brightness(99%) contrast(109%);
}

.delivery-time-title {
    font-weight: 400;
    text-align: right;
    color: #ADD0D4;
}

.delivery-time-datatable {
    font-weight: 400;
    color: #ADD0D4;
}

.card-header {
    border-bottom: 0;
}

.card-header-border {
    border-bottom: 1px solid #EBEDF3;
}

body {
    font-family: 'Titillium';
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    color: #464E5F !important;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text {
    font-size: 14px !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
    flex: 0 0 28px !important;
}

.text-dark {
    color: #464E5F !important;
}

.card.card-custom > .card-header .card-title h5 {
    color: #464E5F !important;
}

.breadcrumb .breadcrumb-item.active i, .breadcrumb .breadcrumb-item.active a,
.breadcrumb .breadcrumb-item:hover i, .breadcrumb .breadcrumb-item:hover a,
.breadcrumb .breadcrumb-item.active i, .breadcrumb .breadcrumb-item.active img,
.breadcrumb .breadcrumb-item.active i, .breadcrumb .breadcrumb-item:hover img {
    color: #FD8C04 !important;
    filter: invert(57%) sepia(63%) saturate(2204%) hue-rotate(356deg) brightness(102%) contrast(105%);
}

body .text-size-13 {
    font-size: 13px;
}

body .text-size-14 {
    font-size: 14px;
}

body .text-size-16 {
    font-size: 16px;
}

body .text-size-18 {
    font-size: 18px;
}

body .text-size-12 {
    font-size: 12px;
}

body .italic {
    font-style: italic;
}

body .color-grey {
    color: #B5B5C3;
}

body .color-grey-2 {
    color: #7E8299;
}

body .color-grey-3 {
    color: #464E5F;
}

body .color-red {
    color: #F64E60;
}

body .color-orange {
    color: #FD8C04;
}

body .text-weight-600 {
    font-weight: 600;
}

body .text-weight-700 {
    font-weight: 700;
}

body .text-weight-400 {
    font-weight: 400;
}

.text-orange {
    color: #FD8C04;
}

.bg-orange {
    background-color: #FD8C04;
}

.text-green {
    color: #2E8F96;
}

.text-green-light {
    color: #7DDFC3;
}

.line-dotted {
    border: 1px dashed #E5EAEE;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 15px 0;
}

.line-dashed {
    border: 1px dashed #E5EAEE;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 15px 0;
}

.card h5 {
    font-weight: 700 !important;
    font-size: 20px !important;
}

.subheader h5 {
    font-weight: 600 !important;
    font-size: 16px !important;
}

.card {
    padding: 24px 24px;
}

.card .card-header {
    padding: 0 !important;
}

.card .card-body {
    padding: 0 !important;
}

.card-toolbar .nav .nav-item .nav-link {
    color: #a3a3af;
    font-size: 15px;
    font-weight: 600;
    border: 0;
}

.card-toolbar .nav .nav-item .nav-link:focus,
.card-toolbar .nav .nav-item .nav-link:hover {
    color: #2E8F96 !important;
    border: 0 !important;
}

.translation-detail .card-toolbar .nav .nav-item .nav-link,
.translation-detail .card-toolbar .nav .nav-item .nav-link:hover,
.translation-detail .card-toolbar .nav .nav-item .nav-link:focus {
    color: #464E5F !important;
    font-weight: 400;
}

.translation-detail .card-toolbar .nav .nav-item .nav-link.active {
    color: #464E5F !important;
    font-weight: 600;
}

.translation-detail .card.card-custom > .card-header.card-header-tabs-line .nav .nav-link {
    padding: 0 0 1rem 0;
}

.card-toolbar .nav .nav-item .nav-link.active {
    color: #2E8F96 !important;
    font-weight: 700;
    border-bottom: 2px solid #2E8F96 !important;
}

.card-toolbar .row button, .card-toolbar .row a {
    margin: 0 5px;
}

.card-translator-report .card-toolbar .nav .nav-item .nav-link {
    color: #B5B5C3;
    font-size: 14px;
    font-weight: 600;
}

.card-translator-report .card-toolbar .nav .nav-item .nav-link:focus,
.card-translator-report .card-toolbar .nav .nav-item .nav-link:hover {
    background: #F3F6F9 !important;
    color: #2E8F96 !important;
}

.card-translator-report .card-toolbar .nav .nav-item .active {
    background: #F3F6F9 !important;
    color: #2E8F96 !important;
    border: 0 !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link:hover > img,
.aside-menu .menu-nav > .menu-item > .menu-link:focus > img {
    filter: invert(57%) sepia(63%) saturate(2204%) hue-rotate(356deg) brightness(102%) contrast(105%);
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading > img,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link > img {
    filter: invert(57%) sepia(63%) saturate(2204%) hue-rotate(356deg) brightness(102%) contrast(105%);
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link:hover .menu-bullet > span,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link:focus .menu-bullet > span,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-bullet > span {
    background-color: #FD8C04 !important;
}

.user-sidebar-wrapper {
    padding: 0 !important;
}

.user-sidebar-wrapper .user-sidebar-header {
    height: 98px;
    max-height: 98px;
    background-color: #FD8C04;
    position: relative;
}

.user-sidebar-wrapper .user-sidebar-header .points {
    background: url(/public/images/points.svg);
    position: absolute;
    top: 0;
    left: 0;
    height: 98px;
    width: 116px;
}

.square-initials {
    background-color: #FFE1A6 !important;
    color: #FD8C04 !important;
    font-weight: 700 !important;
}

.square-initials.font-size-h1 {
    font-size: 28px !important;
}

.topbar-item .square-initials {
    font-size: 16px !important;
}

.user-sidebar-header .square-initials {
    font-size: 20px !important;
}

.user-sidebar-wrapper .user-sidebar-header span {
    font-weight: 700;
    font-size: 16px;
    color: #fff;
}

.user-sidebar-wrapper .user-sidebar-header small {
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}

.user-sidebar-wrapper .user-sidebar-content .list-group .list-group-last-item {
    border-top: 1px solid #E5EAEE !important;
    border: 0;
    border-radius: 0px;
}

.user-sidebar-wrapper .user-sidebar-content .list-group a {
    font-weight: 600;
    font-size: 14px;
    color: #464E5F;
}

.user-sidebar-wrapper .user-sidebar-content .list-group .btn {
    background: #F3F6F9;
    color: #2E8F96;
}

.user-sidebar-wrapper .user-sidebar-content .list-group .list-group-item {
    padding-top: 20px;
    padding-bottom: 20px;
}

.topbar .pulse-primary img {
    filter: invert(46%) sepia(68%) saturate(398%) hue-rotate(135deg) brightness(91%) contrast(92%);
}

.topbar .pulse-primary img.bell-primary {
    filter: invert(53%) sepia(61%) saturate(715%) hue-rotate(352deg) brightness(100%) contrast(108%) !important;
}

.wrapper-notifications {
    border-radius: 4px;
    max-width: 400px;
    min-width: 400px;
}

.bg-header-notifications {
    background-color: #ADD0D4;
    max-height: 98px;
    padding: 4px 0;
    border-radius: 4px 4px 0 0;
    position: relative;
}

.bg-header-notifications h5 {
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #086A71;
}

.wrapper-notifications a {
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.wrapper-notifications .text-muted {
    font-weight: 400;
    font-size: 12px;
}

.symbol-lg-84 .symbol-label {
    width: 84px !important;
    height: 84px !important;
}

.profile-menu .nav .nav-item .nav-link {
    font-weight: 400;
    font-size: 14px;
    color: #7E8299;
    margin: 2px 0;
    padding-top: 15px;
    padding-bottom: 15px;
}

.profile-menu .menu-icon {
    font-size: 18px;
}

.profile-menu .nav .nav-item .nav-link:focus,
.profile-menu .nav .nav-item .nav-link:hover {
    background: #F3F6F9 !important;
    color: #2E8F96 !important;
}

.profile-menu .nav .nav-item .active {
    background: #F3F6F9 !important;
    color: #2E8F96 !important;
}

.profile-menu .nav .nav-item img {
    filter: invert(55%) sepia(8%) saturate(823%) hue-rotate(194deg) brightness(91%) contrast(90%);
    width: 18px;
    height: auto;
}

.profile-menu .nav .nav-item .active img,
.profile-menu .nav .nav-item .nav-link:focus img,
.profile-menu .nav .nav-item .nav-link:hover img {
    filter: invert(52%) sepia(78%) saturate(387%) hue-rotate(135deg) brightness(79%) contrast(83%);
}

.btn {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    border: 0;
}

.btn[type=submit] {
    background-color: #FD8C04;
}

.btn.btn-primary.disabled, .btn.btn-primary:disabled {
    background-color: #FD8C04;
}

.btn[type=submit]:hover,
.btn[type=submit]:focus {
    background-color: #FD8C04 !important;
}

a.btn-green {
    font-weight: 600 !important;
    font-size: 14px;
    padding: 7px;
    background-color: #ADD0D4 !important;
    color: #086A71;
}

a.btn-green:hover,
a.btn-green:focus {
    color: #086A71;
}

a.btn-green-2 {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    background-color: #F3F6F9 !important;
    color: #2E8F96;
}

a.btn-green-2:hover,
a.btn-green-2:focus {
    color: #2E8F96;
}

a.btn-green-5 {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    background-color: #D5F2EA !important;
    color: #00C48C;
}

a.btn-green-5:hover,
a.btn-green-5:focus {
    color: #00C48C;
}

a.btn-purple,
.btn.btn-purple{
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    background-color: #EEE5FF !important;
    color: #8950FC;
}

a.btn-purple:hover,
a.btn-purple:focus {
    color: #8950FC;
}

.modal .modal-footer .btn-orange {
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 9px 15px !important;
    background-color: #FD8C04 !important;
    color: #fff !important;
}

a.btn-orange,
.btn.btn-orange {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    background-color: #FD8C04 !important;
    color: #fff;
}

a.btn-orange:hover,
a.btn-orange:focus {
    color: #fff;
}

a.btn-orange .fal {
    color: #fff;
}

a.btn-orange-2 {
    font-weight: 600 !important;
    font-size: 14px;
    padding: 7px;
    background-color: #FFE1A6 !important;
    color: #FD8C04;
}

a.btn-orange-2:hover,
a.btn-orange-2:focus {
    color: #FD8C04;
}

a.btn-blue-2 {
    font-weight: 600 !important;
    font-size: 14px;
    padding: 7px;
    background-color: #a8dcff !important;
    color: #0099ff;
}

a.btn-blue-2:hover,
a.btn-blue-2:focus {
    color: #0099ff;
}

a.btn-grey {
    padding: 0 5px 0 0 !important;
    color: #2E8F96;
    background-color: #F3F6F9;

}

a.btn-grey:hover,
a.btn-grey:focus {
    color: #2E8F96;
}

.form-group label {
    font-weight: 400;
    font-size: 14px;
    color: #464E5F;
}

.form-group select.disabled {
    pointer-events: none;
    background: #F3F6F9;
}

.form-group input,
.form-group input:focus,
.form-group input:hover,
.form-group select,
.form-group select:focus,
.form-group select:hover {
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #464E5F;
}

.form-group input[readonly],
.form-group input[readonly]:focus,
.form-group input[readonly]:hover {
    background: transparent !important;
}

.header-mobile {
    padding: 0;
}

.header-mobile .burger-icon:hover span {
    background-color: #FD8C04;
}

.header-mobile .burger-icon:hover span::before,
.header-mobile .burger-icon:hover span::after {
    background-color: #FD8C04;
}

.header-mobile .btn:hover .svg-icon svg g [fill] {
    fill: #464E5F;
}

.header-mobile .btn.active .svg-icon svg g [fill] {
    fill: #FD8C04;
}

.dataTables_wrapper .dataTable thead th {
    text-transform: uppercase !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #B5B5C3 !important;
}

.dataTables_wrapper .dataTable thead th .dropdown-menu .text {
    text-transform: capitalize !important;
}

.dropdown-menu {
   min-width: max-content !important;
}

.dataTables_wrapper .dataTable th.sorting_asc,
.dataTables_wrapper .dataTable td.sorting_asc,
.dataTables_wrapper .dataTable th.sorting_desc,
.dataTables_wrapper .dataTable td.sorting_desc {
    color: #FD8C04 !important;
}

.dataTables_wrapper .dataTable th.sorting_desc:after,
.dataTables_wrapper .dataTable td.sorting_desc:after,
.dataTables_wrapper .dataTable th.sorting_asc:after,
.dataTables_wrapper .dataTable td.sorting_asc:after {
    color: #FD8C04 !important;
}

.dataTables_wrapper .dataTable td {
    font-size: 14px !important;
}

.table thead th {
    border-bottom: 0;
}

.dataTables_wrapper .dataTables_paginate {
    float: left !important;
}

.dataTables_wrapper .dataTables_paginate .pagination .page-item:hover:not(.disabled) > .page-link {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    background-color: #2E8F96;
}

.dataTables_wrapper .dataTables_paginate .pagination .page-item.active > .page-link {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    background-color: #2E8F96;
}

.dataTables_wrapper .dataTables_paginate .pagination .page-item.active > .page-link {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    background-color: #2E8F96;
}

.dataTables_wrapper .dataTables_paginate .pagination .previous {
    margin-left: 0;
}

.dataTables_wrapper .dataTables_paginate .pagination .previous > .page-link {
    background: url(/public/images/icons/previous.svg) no-repeat;
    background-size: 10px auto;
    text-indent: -9999px;
    background-position: center center;
    background-color: transparent !important;
}

.dataTables_wrapper .dataTables_paginate .pagination .next > .page-link {
    background: url(/public/images/icons/next.svg) no-repeat;
    background-size: 10px auto;
    text-indent: -9999px;
    background-position: center center;
    background-color: transparent !important;
}

.dataTables_info {
    padding-top: 0 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}

.dataTables_length select {
    background-color: #F3F6F9;
    font-weight: 400 !important;
    font-size: 14px !important;
    padding-left: 22px !important;
}

.btn-complete {
    background: #FD8C04;
    border-radius: 4px;
}

.btn-complete:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f111';
    color: #fff;
    font-size: 20px;
}

.btn-complete:hover:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f058';
    color: #fff;
}

.btn-eye:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f06e';
    color: #2E8F96;
    font-size: 16px;
}

.btn-assign-translation:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f4fc';
    color: #2E8F96;
    font-size: 16px;
}

.btn-assign-validation:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f4fc';
    color: #2E8F96;
    font-size: 16px;
}

.btn-download:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f019';
    color: #2E8F96;
}

.btn-upload:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f093';
    color: #2E8F96;
}

.icon-warning:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f071';
    color: #FF647C;
}

.modal .btn {
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 9px 15px !important;
    background-color: #F3F6F9 !important;
    color: #2E8F96 !important;
}

.modal .btn.modal-submit {
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 9px 15px !important;
    background-color: #FD8C04 !important;
    color: #fff !important;
}

.translation-detail h3.card-label {
    font-weight: 400 !important;
    font-size: 16px !important;
}

h3.card-title {
    font-weight: 700 !important;
    font-size: 16px !important;
}

.translation-detail h2.card-label {
    font-weight: 700 !important;
    font-size: 20px !important;
}

.card.card-custom > .card-header {
    min-height: auto !important;
    margin: 0 0 24px !important;
}

.card.card-custom > .card-header .card-title, .card.card-custom > .card-header .card-title .card-label {
    color: #464E5F !important;
    margin: 0;
}

.translation-detail .icon-info-grey {
    background: url(/public/images/icons/circle-exclamation.svg) no-repeat;
    background-position: center center;
    background-size: 18px 18px;
    margin-right: 10px;
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 6px;
}

.translation-detail .download-all-link {
    text-decoration: underline !important;
    font-size: 14px !important;
}

.translation-detail .btn.btn-light-danger {
    padding: 7px;
}

.translation-detail h5 {
    font-weight: 600 !important;
    font-size: 13px !important;
    margin-bottom: 0 !important;
    color: #464E5F !important;
}

.translation-detail .icon-translation-grey {
    background: url(/public/images/icons/translation.svg) no-repeat;
    background-position: center center;
    background-size: 20px 10.24px;
    margin-right: 10px;
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 6px;
}

.translation-detail .icon-document-grey {
    margin-right: 10px;
    display: inline-block;
    border-radius: 6px;
}

.translation-detail .icon-document-grey:before {
    content: '\f15c';
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    color: #B5B5C3;
    font-size: 18px;
}

.translation-detail .icon-info-circle-grey {
    margin-right: 10px;
    display: inline-block;
    border-radius: 6px;
}

.translation-detail .icon-info-circle-grey:before {
    content: '\f05a';
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    color: #B5B5C3;
    font-size: 18px;
}

.translation-detail .icon-document:before {
    content: '\f15c';
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    color: #B5B5C3;
    font-size: 18px;
}

.translation-detail .icon-calendar:before {
    content: '\f073';
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    color: #B5B5C3;
    font-size: 18px;
}

.translation-detail .icon-calendar-grey:before {
    content: '\f073';
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    color: #B5B5C3;
    font-size: 18px;
}

.translation-detail .icon-calendar-grey {
    margin-right: 10px;
    display: inline-block;
    border-radius: 6px;
}

.translation-detail .card-support-documents {
    padding: 0px 12.5px !important;
}

.translation-detail .card-support-documents .card-title {
    font-weight: 600 !important;
    font-size: 13px !important;
    margin-bottom: 0 !important;
    color: #464E5F !important;
    padding: 20px 0px !important;
}

.translation-detail .card-support-documents .card-header {
    position: relative;
}

.translation-detail .card-support-documents .card-toolbar {
    position: absolute;
    top: 15px;
    right: 45px;
}

.icon-calendar-green {
    background: url(/public/images/icons/calendar.svg) no-repeat;
    background-size: 13px 13px;
    width: 13px;
    height: 13px;
    filter: invert(29%) sepia(27%) saturate(1963%) hue-rotate(141deg) brightness(99%) contrast(94%);
}

.icon-wallet-green {
    background: url(/public/images/icons/wallet.svg) no-repeat;
    /*background-position: center center;*/
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
}

.icon-clock-green {
    background: url(/public/images/icons/clock.svg) no-repeat;
    /*background-position: center center;*/
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
}

.icon-arrow-right-green:after {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f061';
    color: #086A71;
}

.vertical-align-middle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.translation-detail .table-documents {
    border: 1px dashed #DDDFE5;
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;
}

.translation-detail .table-documents .with-dots {
    text-overflow: ellipsis;
    overflow: hidden;
}

.translation-detail .table-responsive {
    max-height: 300px;
    display: inline-block;
    width: 100%;
    overflow-y: auto;
}

.translation-detail .table-documents th {
    border: 1px dashed #DDDFE5;
    font-weight: 700 !important;
    font-size: 14px !important;
}

.translation-detail .table-documents td {
    border: 1px dashed #DDDFE5;
}

.translation-detail .table-support-documents td {
    border: 0;
}

.translation-detail .table-cat-memories {
    border: 1px dashed #DDDFE5;
}

.translation-detail .table-cat-memories td {
    border: 0;
}

.translation-detail .btn-download-file {
    border-radius: 4px;
    color: #2E8F96;
    font-weight: 600;
    font-size: 18px;
}

.translation-detail .btn-delete:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f2ed';
    font-size: 18px;
    color: #FF647C;
}

.translation-detail .btn-download-file:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f56d';
    font-size: 18px;
}

.btn-download-file-disabled:before {
    border-radius: 4px;
    padding: 5px 10px;
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f56d';
    font-size: 18px;
    color: #2E8F96;
    opacity: 0.5;
}

.translation-detail .btn-edit-file {
    border-radius: 4px;
    padding: 5px 10px;
    color: #2E8F96;
    font-weight: 600;
    font-size: 14px;
}

.translation-detail .btn-edit-file:before {
    font-family: "Font Awesome 6 Pro";
    content: '\f305';
    font-weight: 300;
    font-size: 18px;
}

.translation-detail .btn-edit-file:hover {
    background: #F3F6F9;
}

.translation-detail .btn-edit-file:active {
    background: #E5EAEE;
}

.translation-detail .btn-delete-file {
    background-color: #F3F6F9;
    border-radius: 4px;
    padding: 9px 15px;
    color: #2E8F96;
    font-weight: 600;
    font-size: 14px;
}

.translation-detail .btn-delete-file:before {
    font-family: "Font Awesome 6 Pro";
    content: '\f1f8';
    font-weight: 300;
    font-size: 18px;
}

.translation-detail .icon-amount-grey {
    margin-right: 10px;
    display: inline-block;
    border-radius: 6px;
}

.translation-detail .icon-amount-grey:before {
    content: '\f1c0';
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    color: #B5B5C3;
    font-size: 18px;
}

.translation-detail .icon-comments-grey {
    margin-right: 10px;
    display: inline-block;
    border-radius: 6px;
}

.translation-detail .icon-comments-grey:before {
    content: '\f4b6';
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    color: #B5B5C3;
    font-size: 18px;
}

.translation-detail .icon-comment:before {
    content: '\f4b6';
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    color: #B5B5C3;
    font-size: 18px;
}

.wrapper-price .total-price {
    font-weight: 700 !important;
    font-size: 28px !important;
    color: #00C48C !important;
}

.wrapper-price .total-price i {
    color: #00C48C !important;
    font-size: 14px;
    vertical-align: middle;
}

.wrapper-price .translated-words {
    font-weight: 400 !important;
    font-size: 12px !important;
}

.wrapper-price .extra {
    font-weight: 600 !important;
    font-size: 12px !important;
}

.wrapper-price .total-price-subtitle {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #00C48C !important;
}

.dashed-div {
    border: 1px dashed #DDDFE5;
    padding: 30px !important;
}

.translation-detail .svg-icon {
    filter: invert(24%) sepia(70%) saturate(2034%) hue-rotate(160deg) brightness(91%) contrast(94%);
}

.progress-upload {
    width: 70%;
}

.progress-bar {
    background-color: #464E5F;
}

.progress-green-bar {
    background-color: #2E8F96;
}

.progress-green-2-bar {
    background-color: #2E8F96;
}

.progress-black-bar {
    background-color: #464E5F;
}

.progress-yellow-bar {
    background-color: #FFB92E;
}

.progress-red-bar {
    background-color: #FF647C;
}

.progress {
    background: #EEF0F8;
}

.img-validation {
    background: url(/public/images/icons/validation-type.svg) no-repeat;
    background-position: center center;
    background-size: 20px auto;
    width: 20px;
    padding-right: 10px;
}

.img-translation {
    background: url(/public/images/icons/translation-type.svg) no-repeat;
    background-position: center center;
    background-size: auto 15px;
    width: 20px;
    padding-right: 10px;
}

.timeline.timeline-6:before {
    left: 94px;
    top: 5px;
    /*max-height: 80%;*/
    max-height: 100%;
    height: auto !important;
}

.timeline.timeline-6 .timeline-item .timeline-label {
    width: 70px !important;
}

.timeline.timeline-6 .timeline-item {
    display: -webkit-box !important;
}

.timeline.timeline-6 .timeline-item .timeline-badge {
    margin-left: 1.25rem;
    margin-top: 4px;
}

.icon_selector .filter-option-inner-inner {
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
    padding: 0px 2px 1px;
    font-size: 14px;
    color: #464E5F;
}

.bootstrap-select.icon_selector {
    background: #F3F6F9;
    border-radius: 4px;
    border: 0;
    font-weight: 400;
    font-size: 14px;
    color: #464E5F;
}

.bootstrap-select.icon_selector .dropdown-toggle {
    background-color: transparent !important;
}

.border-dashed {
    border-style: dashed !important;
}

.border-bottom-dashed {
    border-bottom: dashed !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link .svg-icon {
    color: #a2a3b7;
    filter: invert(67%) sepia(29%) saturate(146%) hue-rotate(199deg) brightness(92%) contrast(90%);
}

.row.disabled {
    opacity: 0.6;
}

body a {
    cursor: pointer;
}

.dataTable .editableform > .form-group {
    z-index: 999;
    background-color: white;
}

.container-fluid {
    padding-left: 24px;
    padding-right: 24px;
}

.container-green {
    background: #F3F6F9 !important;
    color: #2E8F96 !important;
    border: 0 !important;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.container-green img {
    filter: invert(24%) sepia(70%) saturate(2034%) hue-rotate(160deg) brightness(91%) contrast(94%);
}

.mark-group-read {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #2E8F96 !important;
}

.notifications-ul {
    position: absolute;
    bottom: 0;
}

.topbar-item img {
    width: 30px !important;
    height: 30px !important;
}

.pulse.pulse-primary .pulse-ring {
    border: 10px solid rgba(255, 168, 0, 0.75);
}

.pulse .pulse-ring {
    width: 50px;
    height: 50px;
}

.form-group input[readonly], .form-group input[readonly]:hover {
    background-color: #F3F6F9 !important;
    opacity: 1 !important;
}

.form-group input[readonly]:focus {
    border: 1px solid #E4E6EF;
    background-color: #F3F6F9 !important;
    opacity: 1 !important;
}

.btn-info {
    background: url(/public/images/icons/square/info.svg) no-repeat;
    background-position: center center;
    width: 32px;
    height: 32px;
}

.btn-info:focus, .btn-info:hover {
    background-color: transparent !important;
}

.btn-folder {
    background: url(/public/images/icons/square/folder.svg) no-repeat;
    background-position: center center;
    width: 32px;
    height: 32px;
}

.btn-file {
    background: url(/public/images/icons/square/file.svg) no-repeat;
    background-position: center center;
    width: 32px;
    height: 32px;
}

.btn-translation {
    background: url(/public/images/icons/square/translate.svg) transparent no-repeat !important;
    background-position: center center;
    width: 32px;
    height: 32px;
}

.btn-eye-green {
    background: url(/public/images/icons/square/eye.svg) transparent no-repeat !important;
    background-position: center center;
    width: 32px;
    height: 32px;
}

.btn-download-file:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f56d';
    font-size: 18px;
}

.btn-download-file:hover {
    background: #F3F6F9;
}

.btn-download-file:active {
    background: #E5EAEE;
}

.btn-movements {
    background: url(/public/images/icons/square/movements.svg) no-repeat;
    background-position: center center;
    width: 32px;
    height: 32px;
}

.btn-movements:focus, .btn-movements:hover {
    background-color: transparent !important;
}

.color-green {
    color: #2E8F96 ;
}

a.btn-green-3,
.btn.btn-green-3 {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    color: #2E8F96 !important;
    background-color: #F3F6F9;
}

a.btn-green-3 img,
.btn.btn-green-3 img {
    filter: invert(44%) sepia(78%) saturate(365%) hue-rotate(135deg) brightness(93%) contrast(89%);
}

.btn-bell-exclamation {
    background: url(/public/images/icons/square/bell-exclamation.svg) no-repeat;
    background-position: center center;
    width: 32px;
    height: 32px;
}

.btn-bell-exclamation:focus, .btn-bell-exclamation:hover {
    background-color: transparent !important;
}

.modal-moneybox label {
    font-weight: 600;
    font-size: 14px;
}

.modal-moneybox .modal-body {
    font-size: 14px !important;
}

.modal-header-with-icon {
    justify-content: flex-start !important;
}

.push {
    margin-left: auto !important;
}

a.btn-green-4,
.btn.btn-green-4 {
    color: #fff !important;
    background-color: #2E8F96 !important;
}

.modal-moneybox .btn-green-4:focus, .modal-moneybox .btn-green-4:hover {
    background-color: #2E8F96 !important;
}

.modal-moneybox .text-muted {
    font-weight: 400;
    font-size: 12px;
}

.modal-moneybox .back-green {
    background-color: #D5F2EA;
    padding: 25px 30px;
    border-radius: 4px;
}

.img-finish-orders {
    background: url(/public/images/finish-orders.svg) no-repeat;
    background-size: 100% 100%;
    width: 150px;
    height: 106px;
    position: absolute;
    right: 0;
    opacity: 0.3;
}

.img-pending-orders {
    background: url(/public/images/pending-orders.svg) no-repeat;
    background-size: 100% 100%;
    width: 132px;
    height: 155px;
    position: absolute;
    right: 0;
    opacity: 0.3;
}

.card-green {
    background-color: #2E8F96;
    height: 187px;
    position: relative;
}

.card-green-light {
    background-color: #ADD0D4;
    height: 187px;
    position: relative;
}

.card-green .card-body,
.card-green-light .card-body {
    position: absolute;
    bottom: 0;
}

.card-green .card-body h1,
.card-green-light .card-body h1 {
    font-weight: 700;
    font-size: 28px;
    color: #fff;
}

.card-green .card-body .text,
.card-green-light .card-body .text {
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}

.card-orange {
    background-color: #FD8C04;
    height: 279px;
    position: relative;
}

.card-orange .card-body h1 {
    font-weight: 700;
    font-size: 28px;
    color: #fff;
}

.card-orange .card-body .text {
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

.card-orange .button {
    position: absolute;
    bottom: 10%;
}

.img-new-order {
    background: url(/public/images/new-order.svg) no-repeat;
    background-size: 100% 100%;
    width: 143px;
    height: 162px;
    position: absolute;
    bottom: 0;
    right: 5%;
    opacity: 0.3;
}

a.btn-secondary {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    background-color: #F3F6F9 !important;
    color: #2E8F96 !important;
}

a.btn-return {
    color: #000000;
}

.card-blue {
    background-color: #ADD0D4;
    height: 491px;
    position: relative;
}

.card-blue .card-body h1 {
    font-weight: 700;
    font-size: 28px;
    color: #fff;
}

.card-blue .card-body .text {
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

.img-woman {
    background: url(/public/images/woman.svg) no-repeat;
    background-size: 100% 100%;
    width: 116px;
    height: 210px;
    position: absolute;
    bottom: 0;
    left: 5%;
}

.img-earth {
    background: url(/public/images/earth.svg) no-repeat;
    background-size: 100% 100%;
    width: 320px;
    height: 300px;
    position: absolute;
    right: 0;
    bottom: 10%;
}

.card-moneybox {
    position: relative;
}

.card-moneybox .background-header {
    background-color: #ADD0D4;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 50%;
}

.card-moneybox .card-header {
    background-color: transparent;
    z-index: 1;
}

.balance-container {
    width: 85%;
    margin: 0 auto;
    font-weight: 700;
    font-size: 16px;
    color: #7E8299;
}

.balance-container h2 {
    font-size: 49px;
    font-weight: 700;
}

.btn-info-2 {
    background: url(/public/images/icons/info.svg) no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 18px;
    height: 18px;
    filter: invert(40%) sepia(95%) saturate(4050%) hue-rotate(244deg) brightness(100%) contrast(98%);
}

.btn-info-2:focus, .btn-info-2:hover {
    background-color: transparent !important;
}

a.btn-red-light {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    background-color: #FBE4E8 !important;
    color: #FF647C;
}

a.btn-red-light:hover,
a.btn-red-light:focus {
    color: #FF647C;
}

a.btn-purple-light {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    background-color: #EEE5FF !important;
    color: #8950FC;
}

a.btn-purple-light:hover,
a.btn-purple-light:focus {
    color: #8950FC;
}

.btn-calendar {
    background: url(/public/images/icons/calendar.svg) no-repeat center center;
    background-size: 100% 100%;
    width: auto;
    height: 13px;
    filter: invert(28%) sepia(68%) saturate(1175%) hue-rotate(148deg) brightness(91%) contrast(94%);
}

.order-totals {
    background-color: #F3F6F9;
    font-weight: 700;
    text-align: right;
    color: #2E8F96;
}

.order-totals td {
    border-top: none !important;
}

.order-totals.tax td {
    color: #000000 !important;
}

.order-totals.total  td{
    font-size: 16px !important;
}

.icon-language {
    background: url(/public/images/icons/language.svg) no-repeat center center;
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
}

.language-source {
    background-color: #FFE1A6 !important;
    color: #FD8C04 !important;
}

.language-target {
    background-color: #ADD0D4 !important;
    color: #086A71;
}

.icon-comments {
    background: url(/public/images/icons/comments-green.svg) no-repeat center center;
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
}

.box-comments {
    /*padding: 0 0 0 0 !important;*/
    border-radius: 4px;
    background-color: #F3F6F9;
    min-height: 70px;
}

a.translation-status-completed {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    color: #00C48C;
    background-color: #D5F2EA;
}

a.translation-status-completed:hover,
a.translation-status-completed:focus {
    color: #00C48C;
}

a.translation-status-in-process {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    color: #8950FC;
    background-color: #EEE5FF;
}

a.translation-status-in-process:hover,
a.translation-status-in-process:focus {
    color: #8950FC;
}

.datatable-export-csv {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 15px;
    color: #2E8F96 !important;
    background-color: #F3F6F9;
}
.datatable-export-csv::before {
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    content: '\f019';
    margin-right: 10px;
}

.dt-buttons {
    float: right;
}

#a4b_scrolltop {
    background-color: #2E8F96;
}

#a4b_scrolltop svg {
    background-color: #2E8F96;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon, .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
    color: #FD8C04 !important;
    filter: none;
}

.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-icon, .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-icon {
    color: #FD8C04 !important;
    filter: none;
}

#btn-active-low-balance {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.form-control[disabled=disabled],
.form-control[disabled=disabled]:hover {
    color: #B5B5C3;
}

#tab-users label.form-check-label.required:after {
    content: '' !important;
}

.selectdiv:after {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f0dd';
    font-size: 16px;
    right: 2rem;
    top: 0.4rem;
    position: absolute;
}

.selectdiv select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.selectdiv select:focus,
.selectdiv select:hover {
    /*background: #F3F6F9;*/
    /*border: 0;*/
    /*color: #B5B5C3 !important;*/
}

.profile-translator .file-upload-container {
    padding: 30px;
    background: #F3F6F9;
    border-radius: 4px;
    min-height: 118px;
    border: 1px dashed #ADD0D4;
}

.profile-translator .file-upload-container h3 {
    font-weight: 600 !important;
    font-size: 14px;
}

.profile-translator .file-upload-container span {
    font-weight: 400 !important;
    font-size: 12px;
}

.profile-translator .file-upload-container .dz-preview, .dz-file-preview {
    display: none;
}

.profile-translator .file-upload-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f574';
    color: #ADD0D4;
    font-size: 23px;
}

.profile-translator .file-document-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f15c';
    color: #B5B5C3;
    font-size: 14px;
}

.profile-translator .file-trash-img,
.profile-translator .file-star-img {
    cursor: pointer;
}

.profile-translator .file-trash-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f2ed';
    color: #2E8F96;
    font-size: 17px;
}

.profile-translator .file-star-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f005';
    color: #2E8F96;
    font-size: 17px;
}

.profile-translator #documents_container {
    padding-left: 0;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;

}

.profile-translator #documents_container .document-row {
    list-style: none;
    opacity: 0.7;
    margin-bottom: 10px;
    border: 1px dashed #ADD0D4;
    border-radius: 4px;
    padding:15px;
}

.profile-translator #documents_container .document-row .document-row-content {
    height: 38px;
}

.profile-translator #documents_container .document-row .d-block {
    line-height: 1;
}

.profile-translator #documents_container .document-row  .row-left {
    /*background-color: #F3F6F9;*/
    /*border: 1px solid #DDDFE5;*/
}

.profile-translator #documents_container .document-row  .row-right {
    text-align: right;
}

.profile-translator #documents_container .document-row  .row-center {
    text-align: center;
    background-color: #F3F6F9;
    border-radius: 4px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-translator #documents_container .document-row  .row-right img {
    cursor: pointer;
}

.profile-translator #documents_container .document-row span {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1;
}

.profile-translator #documents_container .document-row .doc-name {
    color: #B5B5C3;
    overflow: hidden;
    display: inline-flex;
    width: 100%;
    clear: both;
    white-space: nowrap;
}

.profile-translator #documents_container .document-row .doc-date-add {
    color: #464E5F;
}

.profile-translator #documents_container .file-type-name {
    padding-left: 0;
}

.profile-translator #documents_container .file-name,
.profile-translator #documents_container .file-add {
    margin-left: 5px !important;
}

.file-validated-img {
    display: inline-block;
}

.file-validated-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f316';
    color: #2E8F96;
    font-size: 30px;
    margin-left: 0;
}

.file-no-validated-img {
    display: inline-block;
}

.file-no-validated-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f316';
    color: #F64E60;
    font-size: 30px;
    margin-left: 0;
}

.file-pdf-img {
    display: inline-block;
}

.file-pdf-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f1c1';
    /*color: #2E8F96;*/
    font-size: 30px;
    margin-left: 3px;
}

.contract-document-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f56d';
    font-size: 13px;
    margin-right: 5px;
}

.icon-truck-grey {
    display: inline-block;
}
.icon-truck-grey:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    color: #B5B5C3;
    content: '\f0d1';
    font-size: 18px;
    margin-right: 5px;
    border-radius: 4px;
    padding: 7px;
}

.form-tracking input,
.form-tracking input:hover,
.form-tracking input:focus {
    height: 38px;
    display: inline-block;
    background: #F3F6F9;
    border: 0;
    position: relative;
    color: #B5B5C3;
}

.shipping-container .back-green {
    padding: 15px;
    background-color: rgba(213, 242, 234, 1);
    border: 1px solid #7DDFC3;
    border-radius: 4px;
    color: #2E8F96;
    margin-top: 10px;
    margin-bottom: 16px;
}

.shipping-container .back-green div {
    color: #2E8F96;
    font-weight: 600;
    font-size: 14px;
}

.shipping-container .back-green .tracking-user:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f007";
    font-weight: 300;
    font-size: 14px;
    margin-right: 5px;
}

.shipping-container .back-green .tracking-phone:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f095";
    font-weight: 300;
    font-size: 14px;
    margin-right: 5px;
}

.shipping-container .back-green .tracking-at:before {
    font-family: "Font Awesome 6 Pro";
    content: "\40";
    font-weight: 300;
    font-size: 14px;
    margin-right: 5px;
}

.shipping-container .back-green .tracking-address:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f3c5";
    font-weight: 300;
    font-size: 14px;
    margin-right: 5px;
}

.shipping-container .back-green .tracking-address {
    font-weight: 400 !important;
}

.alert-light-warning .info-icon:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f05a";
    font-weight: 300;
    font-size: 16px;
    color: #FFA800;
}

.span-grey {
    color: #B5B5C3;
    font-weight: 400;
    font-size: 14px;
}

.payment-bank-transfer-icon:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f19c';
    font-size: 17px;
    color: #2E8F96;
}

#tab-payment-method .btn-default {
    background-color: #D5F2EA !important;
    color: #00C48C;
    font-weight: 600 !important;
}

.tab-translator-payment-method .btn-red {
    background-color: #FBE4E8 !important;
    color: #FF647C;
    font-weight: 600 !important;
}

.tab-translator-payment-method .btn-default:hover,
.tab-translator-payment-method .btn-default:focus {
    color: #00C48C !important;
    cursor: default !important;
}

.tab-translator-payment-method .btn-red:hover,
.tab-translator-payment-method .btn-red:focus {
    color: #FF647C !important;
    cursor: default !important;
}

#tab-payment-method .btn-success {
    background-color: #2E8F96 !important;
    color: #fff;
    font-weight: 600 !important;
}

.tab-translator-payment-method .trash-container,
.tab-translator-payment-method .star-container,
.tab-translator-payment-method .square-item {
    text-align: center;
    background-color: #F3F6F9;
    border-radius: 4px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 32px;
}

.file-trash-img,
.file-star-img {
    cursor: pointer;
}

.file-trash-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f2ed';
    color: #2E8F96;
    font-size: 17px;
}

.file-star-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f005';
    color: #2E8F96;
    font-size: 17px;
}

#newBankAccountModal input,
#newPaypalAccountModal input {
    height: 38px;
    display: inline-block;
    background: #F3F6F9;
    border: 0;
    position: relative;
    color: #B5B5C3;
}

#newBankAccountModal .file-upload-container {
    padding: 30px;
    background: #F3F6F9;
    border-radius: 4px;
    min-height: 118px;
    border: 1px dashed #ADD0D4;
}

#newBankAccountModal .file-upload-container .file-upload-img:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f574';
    color: #ADD0D4;
    font-size: 23px;
}

.tab-translator-payment-method .card .card-body {
    padding-left: 40px !important;
}

.tab-translator-payment-method .card .card-header .card-title.collapsed .arrow-right-img:after {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f061';
    color: #2E8F96;
    font-size: 17px;
}

.tab-translator-payment-method .card .card-header .card-title:not(.collapsed) .arrow-right-img:after {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f063';
    color: #2E8F96;
    font-size: 17px;
}

@keyframes spinner {
    to {transform: rotate(360deg);}
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite;
}

div[data-section-id] {
    padding: 0 !important;
}

.btn[type=submit][disabled] {
    cursor: not-allowed !important;
    background-color: #FD8C04 !important;
}

.alert.alert-custom.alert-light-primary {
    background-color: #D5E9FA;
}

.alert-light-primary .svg-icon {
    font-size: 18px !important;
}

.alert-light-danger .svg-icon {
    color: #FF647C !important;
    font-size: 18px !important;
}

.alert {
    margin-bottom: 24px;
}

.alert.alert-custom.alert-light-primary .alert-text {
    color: #464E5F !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.card-title .square-item {
    text-align: center;
    background-color: #F3F6F9;
    border-radius: 4px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 32px;
}

.card-title .square-item i.green {
    color: #086A71;
}

.flex-row {
    flex-direction: row;
    display: flex;
}

.flex-column {
    flex-direction: column;
    display: flex;
}

.number-words-info {
    flex-direction: column;
    display: flex;
    flex: 1 1 auto;
}

.number-words-count {
    font-size: 14px !important;
    color: #2E8F96;
    font-weight: bold;
}

.flex-body {
    display: flex;
}

.label.label-purple {
    background-color: #c235ff;
}

.titles h1 {
    font-weight: 700;
    font-size: 39px;
}
.titles h3{
    font-weight: 600;
    font-size: 16px;
}

.titles a {
    color: #2E8F96;
    text-decoration: none;
}

.logo-bt {
    width: 400px;
}

a.text-primary.link-green {
    color: #2E8F96 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}

a.text-primary.link-green:hover,
a.text-primary.link-green:focus {
    color: #2E8F96 !important;
}

.support-languages-selector {
    padding: 0px 0px !important;
    height: auto !important;
}

.vertical-align-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.memory-box {
    padding: 20px 15px;
    margin-bottom: 25px;
}

.memory-box .card-header {
    min-height: 0 !important;
}

.memory-box .card-header .card-title .card-label {
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #464E5F !important;
}

.memory-box .card-body .title {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #464E5F !important;
}

.memory-box .card-body .date {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #B5B5C3 !important;
}

@media only screen and (max-width: 600px) {
    .logo-bt {
        width: auto;
    }

    .translation-detail .card {
        padding: 15px 20px;
    }

    .translation-detail .general-info .btn-orange-2,
    .translation-detail .general-info .btn-green {
        padding: 7px 15px;
    }

    .translation-detail .dashed-div {
        padding: 15px !important;
    }

    .translation-detail .table-support-documents td {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .translation-detail .position-absolute {
        position: relative !important;
    }

    .translation-detail .primary-container {
        order: 2;
    }

    .translation-detail .timeline-container {
        order: 1;
    }

    .mark-as-readed-notifications:before {
        margin-right: 0;
    }

    .mark-as-readed-notifications > span {
        display: none;
    }

    .mark-as-deleted-notifications:before {
        margin-right: 0;
    }

    .mark-as-deleted-notifications > span {
        display: none;
    }

    .translation-detail .timeline-container .card-label {
        font-weight: 600 !important;
        font-size: 14px !important;
    }

    .translation-detail .timeline-container .card-body {
        padding: 0 !important;
    }

    .translation-detail .btn-reject {
        display: inline;
    }

     .translation-detail .btn-finish {
         display: inline;
     }

     .translation-detail .label-status {
         font-weight: 600;
         font-size: 14px;
         padding: 15px 7px;
        gap: 10px;
         border-radius: 4px;
     }

    .translation-detail .my-24 {
        margin: 24px 0 !important;
    }

    .translation-detail .mb-24 {
        margin-bottom: 24px !important;
    }

    .translation-detail .mt-24 {
        margin-top: 24px !important;
    }

    .translation-detail .mb-16 {
        margin-bottom: 16px !important;
    }

    .translation-detail .mb-8 {
        margin-bottom: 8px !important;
    }

    .translation-detail  .comments-container textarea {
        height: 76px;
    }

    .sticky-container {
        background-color: #fff;
        width: 100vw;
        position: sticky;
        bottom: 0;
        padding: 15px 0;
        margin-left: -24px !important;
        margin-bottom: -13px !important;
        z-index: 2;
    }

    .translation-detail .alert-light-primary .svg-icon {
        color: #0084F4 !important;
        filter: none !important;
    }

    .translation-detail .dropdown-menu {
        width: auto !important;
        min-width: auto !important;
    }

    .translation-detail .memories-container-mobile {
        border: 1px dashed #DDDFE5;
        width: 100%;
        table-layout: fixed;
        overflow-wrap: break-word;
        padding: 0.75rem;
    }

    .translation-detail .documents-container-mobile {
        width: 100%;
    }

    .translation-detail .btn-download-file:hover {
        background: none !important;
    }

    .translation-detail .btn-download-file:active {
        background: none !important;
    }
}

.hidden {
    display: none !important;
}

.square-notificiation-icon {
    border-radius: 6px;
    width: 32px;
    height: 32px;
    position: relative;
}

.square-notificiation-grey-icon {
    border-radius: 6px;
    width: 32px;
    height: 32px;
    position: relative;
    background-color: transparent;
}

.square-notificiation-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.square-notificiation-grey-icon i {
    color: #B5B5C3 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.readed {
    color: #464E5F !important;
}

.message-grey {
    color: #7E8299 !important;
}

.mark-as-readed-notifications:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f2b6';
    margin-right: 5px;
}

.mark-as-deleted-notifications:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f2ed';
    margin-right: 5px;
}

.selected-items-container {
    font-size: 14px;
}

.table-checkable .checkbox {
    display: inline-block;
}

.table-checkable .checkbox.checkbox-success > input:checked ~ span {
    background-color: #2E8F96 !important;
}

.bootstrap-select > .dropdown-toggle.btn-light, .bootstrap-select > .dropdown-toggle.btn-secondary {
    background-color: #F3F6F9 !important;
}

.select2-selection__choice {
    background: #2E8F96 !important;
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}

.checkout-container .select2-selection__choice__remove:before {
    color: #fff !important;
}

.select2-container--default .select2-results__option.select2-results__option--highlighted {
    background: #ADD0D4 !important;
    color: #2E8F96 !important;
}

.select2-results__option {
    padding-right: 20px;
    vertical-align: middle;
}

.select2-results__option:before {
    content: "";
    display: inline-block;
    position: relative;
    height: 20px;
    width: 20px;
    border: 2px solid #e9e9e9;
    border-radius: 4px;
    background-color: #fff;
    margin-right: 20px;
    vertical-align: middle;
}

.select2-results__option[aria-selected=true]:before {
    font-family: fontAwesome, serif;
    content: "\f00c";
    color: #fff;
    background-color: #2E8F96;
    border: 0;
    display: inline-block;
    padding-left: 2px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #fff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #eaeaeb;
    color: #272727;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-radius: 4px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #2E8F96;
    border-width: 2px;
}

.select2-container--default .select2-selection--multiple {
    border-width: 2px;
}

.select2-container--open .select2-dropdown--below {
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.select2-selection .select2-selection--multiple:after {
    content: 'hhghgh';
}

.label-info i {
    font-size: 12px;
    color: #2E8F96;
}

.project-source-current-index {
    font-size: 13px;
}

.translation-detail .dashed-container {
    border: 1px dashed #DDDFE5;
    padding: 8px 16px 8px 16px;
}

.sticky-container {
    background-color: #fff;
    position: sticky;
    bottom: 0;
    padding: 15px 0;
    margin-bottom: 2px;
    display: flex;
    z-index: 2;
}

.sticky-container .center-progress-bar {
    flex:1;
    display: flex;
    justify-content: center;
}

.sticky-container .card-toolbar, .button-return {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.sticky-container .card-toolbar {
    margin: 0.5rem 24px 0.5rem 0;
}

.translation-detail .support-container-scroll {
    max-height: 235px;
}

.translation-detail .btn-success {
    background-color: #2E8F96 !important;
    color: #fff;
    font-weight: 600 !important;
}

.translation-detail .source-container button.dropdown-toggle {
    font-weight: normal !important;
}

.translation-detail .timeline-container .card-custom {
    padding: 25px 30px 25px 30px !important;
    min-height: 100%;
}

.translation-detail .timeline-container .timeline {
    overflow: auto;
    /*height: 100%;*/
}

.translation-detail .timeline-container .timeline .timeline-item .timeline-label {
    font-size: 14px;
}

.translation-detail .timeline-container .timeline .timeline-item .timeline-label .time {
    font-weight: 400;
    font-size: 12px;
}

.translation-detail #accordionSupportDocuments.accordion-toggle-arrow .card .card-header .card-title.collapsed:after {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f078';
    color: #2E8F96;
    font-size: 17px;
    transform: none;
}

.translation-detail #accordionSupportDocuments.accordion-toggle-arrow .card .card-header .card-title:after {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f077';
    color: #2E8F96;
    font-size: 17px;
    transform: none;
}

.return-link {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #464E5F !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-left:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f053';
    color: #464E5F;
    font-size: 17px;
}

.bootstrap-select > .btn.dropdown-toggle.btn-light {
    background: #F3F6F9 !important;
    font-weight: normal !important;
    height: 40px;
}

.dropdown-menu li:hover > a {
    background-color: #ADD0D4 !important;
}

.dropdown-menu li > a > span {
    font-size: 13px !important;
    font-weight: normal !important;
}

.dropdown-menu li:hover > a > span {
    color: #2E8F96 !important;
}

.dropdown-menu li.selected > a {
    background-color: #086A71 !important;
}

.dropdown-menu li.selected > a > span {
    color: #fff !important;
    font-weight: bold !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #ADD0D4 !important;
}

.dropdown-item.active > span,
.dropdown-item:active > span {
    color: #2E8F96 !important;
}

.bootstrap-select.disabled > .dropdown-toggle.btn-light .filter-option,
.bootstrap-select.disabled > .dropdown-toggle.btn-secondary .filter-option {
    color: #B5B5C3;
}

.bootstrap-select .filter-option {
    display: flex;
    align-items: center;
}

.background-color-none:before {
    background-color: unset !important;
}

.btn-sent-for-validation {
    width: auto;
    height: 22px;
    text-align: center;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(137, 80, 252, 1);
    background: rgba(238, 229, 255, 1);
}

.btn-job-completed {
    width: auto;
    height: 22px;
    text-align: center;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 196, 140, 1);
    background: rgba(213, 242, 234, 1);
}

.modal-incidence {

}

.modal-incidence .modal-header{
    border-bottom: none !important;
}

.modal-info-incidence-cancel {
    color: black !important;
    margin-right: 30px !important;
}

.sticky-container .center-progress-bar {
    flex:1;
    display: flex;
    justify-content: center;
}

.sticky-container .card-toolbar, .button-return {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.sticky-container .card-toolbar {
    margin: 0.5rem 24px 0.5rem 0;
}

.info-date {
    justify-content: right;
    margin-bottom: 24px !important;
}

.info-date div {
    margin-right: 15px;
}

.project-language-combinations {
    margin-bottom: 24px;
}

.project-comments-price {
    margin-bottom: 24px;
}

.project-comments-price .textarea-comments {
    padding-right: 12px;
}

.project-comments-price .textarea-price {
    padding-left: 12px;
}

.project-document-line {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 44px;
    margin-bottom: 8px;
}

.btn-language-combination {
    padding: 5px 10px !important;
}

.title-section {
    margin-bottom: 16px;
}

.justify-content-right {
    justify-content: right;
}

.project-support-document-info {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: 1px dashed rgba(221, 223, 229, 1);
    padding-left: 12.5px;
    padding-right: 12.5px;
}

.project-document-info {
    width: calc(100% - 36px);
    height: 44px;
    border-radius: 4px;
    border: 1px dashed rgba(221, 223, 229, 1);
    padding-left: 12.5px;
    padding-right: 12.5px;
}

.project-document-info-available {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: 1px dashed rgba(221, 223, 229, 1);
    padding-left: 12.5px;
    padding-right: 12.5px;
}

.project-document-checkbox {
    border: none;
    height: 36px;
    width: 36px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 12.5px;
}

.project-document-checkbox > i {
    font-size: 22px;
}

.btn-total-files {
    margin-right: 24px;
    width: 117px;
    height: 22px;
    text-align: center;
    border-radius: 4px;
    margin-right: 24px;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(137, 80, 252, 1);
    background: rgba(238, 229, 255, 1);
}

.download-documents {
    font-size: 14px;
    font-weight: 600;
    color: #2E8F96;
}
.download-documents a:active {
}

.download-project-documents a:hover {
    color: rgba(8, 106, 113, 1) !important;
    background-color: #D5E9EA !important;
}

.project-support-documents {
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
}

.project-source-documents table {
    border-collapse:separate;
    border-spacing: 0 1em;
    border: none !important;
}

.project-source-documents tr {
    border: 1px dashed #DDDFE5;
}

.btn-deadline-date {
    padding: 5px 10px;
}

.project-document-line .btn-sm {
    font-size: 14px !important;
}

.checkbox-translation-success {
    color: #00C48C;
}

.documents-progress-bar {
    display: flex;
}

.documents-progress-bar .progress{
    background: #EEF0F8;
}

.justify-content-right {
    justify-content: right;
}

.project-support-document-info {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: 1px dashed rgba(221, 223, 229, 1);
    padding-left: 12.5px;
    padding-right: 12.5px;
}

.documents-progress-bar {
    display: flex;
}

.documents-progress-bar .number-incidences {
    font-size: 14px !important;
    margin-left: 5px;
    color: red;
}

.documents-progress-bar-in-process {
    background-color: #2E8F96 !important;
}

.documents-progress-bar-finished {
    background-color: #00C48C !important;
}

.bootstrap-select > .btn.dropdown-toggle.btn-light {
    background: #F3F6F9 !important;
    font-weight: normal !important;
    height: 40px;
}

.dropdown-menu li:hover > a {
    background-color: #ADD0D4 !important;
}

.dropdown-menu li > a > span {
    font-size: 13px !important;
    font-weight: normal !important;
}

.dropdown-menu li:hover > a > span {
    color: #2E8F96 !important;
}

.dropdown-menu li.selected > a {
    background-color: #086A71 !important;
}

.dropdown-menu li.selected > a > span {
    color: #fff !important;
    font-weight: bold !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #ADD0D4 !important;
}

.dropdown-item.active > span,
.dropdown-item:active > span {
    color: #2E8F96 !important;
}

.bootstrap-select.disabled > .dropdown-toggle.btn-light .filter-option,
.bootstrap-select.disabled > .dropdown-toggle.btn-secondary .filter-option {
    color: #B5B5C3;
}

.bootstrap-select .filter-option {
    display: flex;
    align-items: center;
}

.background-color-none:before {
    background-color: unset !important;
}

.btn-sent-for-validation {
    width: auto;
    height: 22px;
    text-align: center;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 4px;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(137, 80, 252, 1);
    background: rgba(238, 229, 255, 1);
}

.btn-status-translating {
    margin-right: 24px;
    width: auto;
    padding-left: 7px;
    padding-right: 7px;
    height: 22px;
    text-align: center;
    border-radius: 4px;
    margin-right: 24px;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(137, 80, 252, 1);
    background: rgba(238, 229, 255, 1);
}

.btn-show-support-documents {
    border-radius: 4px;
}

.btn-show-support-documents:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    content: '\f078';
    color: #2E8F96;
    font-size: 20px;
}

.project-source-documents-segments {
    height: 569px;
    overflow-y: auto;
    overflow-x: hidden;
}

#original-source-documents-segments .card {
    border: none;
    padding-right: unset;
    padding-left: unset;
    padding-top: unset;
}

#original-source-documents-segments .card-title {
    border-radius: 4px;
    color: #3F4254;
}

#original-source-documents-segments .card-title:after{
    color: #3F4254;
    font-family: "Font Awesome 6 Pro";
    content: '\f0dd';
    font-weight: 300;
    font-size: 20px;
}

#original-source-documents-segments .segment {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.textarea-comments .cat-show {
    min-height: 128px;
}

.cat-detail .btn-language-combination {
    background: #EEF0F8;
    border-radius: 4px;
    font-weight: 600 !important;
    font-size: 14px;
}

.cat-detail .segment-group {
    padding: 10px;
    border-bottom: 1px solid transparent;
}

.cat-detail .segment-group:hover {
    cursor: pointer;
    background-color: #F5FAFA !important;
}

.cat-detail .segment-group .segment-editor {
    background-color: #fff;
}

.cat-detail .segment-group:hover .segment-editor {
    border: 1px solid #ADD0D4 !important;
}

.cat-detail .segment-group.active {
    background-color: #EBF4F5;
}

.cat-detail .segment-group.active .segment-editor,
.cat-detail .segment-group.active .segment-editor {
    border: 1px solid #ADD0D4 !important;
}

.cat-detail  .segment {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: justify;
}

.cat-detail .segment .textarea-container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
}

.cat-detail  .segment .segment-editor {
    resize: none;
    min-height: 100%;
    overflow: auto;
    border: 1px solid #DDDFE5;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
}

.cat-detail .segment-group .segment-editor.danger {
    border: 1px solid #D73C37 !important;
}

.cat-detail  .segment .translated-segment-editor {
    border: 1px solid transparent !important;
}

.cat-detail  .segment .validated-segment-editor {
    border: 1px solid transparent !important;
}

.cat-detail  .segment .segment-editor:focus,
.cat-detail  .segment .segment-editor:target,
.cat-detail  .segment .segment-editor:hover,
.cat-detail  .segment .segment-editor:active {
    outline: none;
}

.cat-detail .document-end-position {
    margin: 35px 0 15px 0;
}

.cat-detail .document-end-position span {
    color: #7E8299;
    font-size: 14px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.cat-detail.card {
    padding: 25px 0 !important
}

.cat-detail.card .card-header {
    padding: 24px 24px !important;
    align-items: center;
    justify-content: center;
}

.cat-detail.card .card-header .general-options {
    gap: 35px;
    display: flex;
    justify-content: flex-end;
}

.cat-detail.card .card-header .general-options .btn {
    background-color: #F3F6F9;
    color: #2E8F96;
}

.cat-detail.card .card-header .btn-eye:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f06e";
    color: #2E8F96;
    font-weight: 300;
    font-size: 20px;
    cursor: pointer;
}

.cat-detail.card .card-header .btn-download:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f56d";
    color: #2E8F96;
    font-weight: 300;
    font-size: 20px;
    cursor: pointer;
}

.cat-detail.card .card-header .btn-checkbox-disabled:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f14a";
    color: #2E8F96;
    font-weight: 300;
    font-size: 22px;
    opacity: 0.5;
    cursor: pointer;
}

.cat-detail.card .card-header .btn-checkbox:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f14a";
    color: #2E8F96;
    font-weight: 300;
    font-size: 22px;
    cursor: pointer;
}

.cat-detail.card .card-body {
    overflow-y: scroll;
    max-height: 1000px;
    padding: 0 20px !important;
}

.cat-detail .card-body::-webkit-scrollbar {
        display: none;
}

.cat-detail .segment-group .segment .btn-check {
    height: auto;
    width: auto;
    margin-top: 0;
    margin-left: 10px;
}

.cat-detail .segment-group .segment .btn-checkbox-disabled:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f14a";
    color: #DDDFE5;
    font-weight: 300;
    font-size: 22px;
}

.cat-detail .segment-group .segment .btn-checkbox-pending:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f14a";
    color: #2E8F96;
    font-weight: 300;
    font-size: 22px;
}

.cat-detail .segment-group .segment .btn-checkbox:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f14a";
    color: #00C48C;
    font-weight: 600;
    font-size: 22px;
}

.cat-detail .segment-group .segment .btn-comments.active,
.cat-detail .segment-group .segment .btn-comments-translator.active {
    height: auto;
    width: auto;
    margin-top: 0;
    margin-left: 10px;
}

.cat-detail .segment-group .segment .btn-comments.active:before,
.cat-detail .segment-group .segment .btn-comments-translator.active:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f4b6";
    color: #2E8F96;
    font-weight: 300;
    font-size: 16px;
}

.cat-detail .segment-group .segment .btn-comments.active:hover:before,
.cat-detail .segment-group .segment .btn-comments.active:target:before,
.cat-detail .segment-group .segment .btn-comments.active:focus:before,
.cat-detail .segment-group .segment .btn-comments.active:active:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f4b6";
    color: #086A71;
    font-weight: 300;
    font-size: 16px;
}

.cat-detail .segment-group .segment .btn-comments-translator.active:hover:before,
.cat-detail .segment-group .segment .btn-comments-translator.active:target:before,
.cat-detail .segment-group .segment .btn-comments-translator.active:focus:before,
.cat-detail .segment-group .segment .btn-comments-translator.active:active:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f4b6";
    color: #086A71;
    font-weight: 300;
    font-size: 16px;
}

.cat-detail .segment-group .segment .btn-edit {
    height: auto;
    width: auto;
    margin-top: 0;
    margin-left: 10px;
}

.cat-detail .segment-group .segment .btn-edit:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f305";
    color: #2E8F96;
    font-weight: 300;
    font-size: 16px;
}

.cat-detail .segment-group .segment .btn-edit:hover:before,
.cat-detail .segment-group .segment .btn-edit:target:before,
.cat-detail .segment-group .segment .btn-edit:focus:before,
.cat-detail .segment-group .segment .btn-edit:active:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f305";
    color: #086A71;
    font-weight: 300;
    font-size: 16px;
}

.cat-detail .segment-group .segment .segment-number {
    height: auto;
    width: auto;
    margin-top: 0;
    font-size: 12px;
    color: #7E8299;
    margin-right: 5px;
}

.cat-detail .segment-group .segment .text-original-container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
}

.cat-detail .segment-group .segment .arrow {
    height: auto;
    width: auto;
    margin-top: 0;
}

.cat-detail .segment-group .segment .arrow-right:after {
    font-family: "Font Awesome 6 Pro";
    content: "\f061";
    color: #2E8F96;
    font-weight: 300;
    font-size: 20px;
}

#cat-sticky {
    background-color: #fff;
    position: sticky;
    bottom: 0;
    margin-bottom: 2px;
    display: flex;
    z-index: 2;
}

#cat-sticky .cat-sticky-container {
    width: 100%;
}

#cat-sticky .cat-sticky-container  .btn-primary {
    background-color: #FD8C04 !important;
    color: #fff !important;
}

.cat-sticky-container .card-toolbar {
    margin: 0.5rem 0px 0.5rem 0 !important;
}

.cat-sticky-container .btn {
    background-color: #F3F6F9;
    color: #2E8F96;
}

.cat-sticky-container .btn-previous,
.cat-sticky-container .btn-next {
    background-color: #2E8F96 !important;
    color: #fff !important;
}

.cat-sticky-container .btn-previous:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f060";
    color: #fff;
    font-weight: 300;
    margin-right: 10px;
}

.cat-sticky-container .btn-next:after {
    font-family: "Font Awesome 6 Pro";
    content: "\f061";
    color: #fff;
    font-weight: 300;
    margin-left: 10px;
}
.cat-sticky-container .first-container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
}
.cat-sticky-container .progress-bar-container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: center;
}

#cat-sticky .progress-bar-container .btn-comments {
    height: auto;
    width: auto;
    margin-top: 0;
    margin-left: 10px;
}

#cat-sticky .progress-bar-container .btn-comments:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f4b6";
    color: #2E8F96;
    font-weight: 300;
    font-size: 16px;
}

#cat-sticky .progress-bar-container .btn-comments.danger:before {
    color: #D73C37 !important;
}

.cat-sticky-container .card-toolbar {
    gap: 15px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
}

.cat-sticky-container .spinner.spinner-darker-success:before {
    border: 2px solid #2E8F96;
    border-right: 2px solid transparent;
}

.cat-sticky-container .progress {
    background: #EEF0F8 !important;
}

.toast.toast-success {
    background-color: #1E1E2D !important;
}

#toast-container > .toast-success {
    background-image: none !important;
}

.toast-bottom-right {
    bottom: 70px;
}

#saving-label {
    color: #B5B5C3;
    font-size: 14px;
}

.modal-content .grey-incidence {
    color: #B5B5C3;
    font-size: 12px;
    font-weight: 600;
}

.modal-content .date {
    color: #B5B5C3;
    font-size: 12px;
    font-weight: 400;
}

#translation-incidences-container {
    position: fixed;
    z-index: 9999;
    bottom: 65px;
    right: 24px;
}

#translation-incidences-container .alert .alert-title {
    color: #464E5F;
    font-size: 16px;
    font-weight: 600;
}

#translation-incidences-container .alert .alert-text {
    color: #7E8299;
    font-size: 14px;
    font-weight: 400;
}

#translation-incidences-container .alert .alert-link a {
    color: #464E5F !important;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
}

.icon-badge-group .icon-badge-container:first-child {
    margin-left:0
}

.icon-badge-container {
    position:relative;
    display: inline-block;
}

.icon-badge-container .icon-badge {
    display: none;
}

.icon-badge-container .icon-badge.active {
    background-color: red;
    font-size: 11px;
    color: white;
    text-align: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: 18px;
    display: block;
}

.incidence_translator_modal .btn-check {
    height: auto;
    width: auto;
    margin-left: 0px !important;
}

.incidence_translator_modal .btn-checkbox-modal-disabled:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f14a";
    color: #DDDFE5;
    font-weight: 300;
    font-size: 22px;
}

.incidence_translator_modal .btn-checkbox-modal-pending:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f14a";
    color: #2E8F96;
    font-weight: 300;
    font-size: 22px;
}

.incidence_translator_modal .btn-checkbox-modal:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f14a";
    color: #00C48C;
    font-weight: 600;
    font-size: 22px;
}

#cat-sticky .status-label-purple {
    font-size: 14px;
    font-weight: 600;
    color: #8950FC;
    padding: 9px 15px;
    border-radius: 4px;
    background-color: #EEE5FF
}

.cat-detail .segment-tabs .nav-item a.active,
.cat-detail .segment-tabs .nav-item a:hover {
    font-weight: 600 !important;
    color: #464E5F !important;
    border-bottom: 2px solid #2E8F96 !important;
}

.cat-detail .segment-tabs {
    font-size: 14px;
    color: #464E5F;
}

.cat-detail .segment-tabs .tab-memory:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f7c2";
    font-weight: 300;
    font-size: 22px;
    margin-right: 10px;
}

.cat-detail .segment-tabs .tab-tm:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f865";
    font-weight: 300;
    font-size: 22px;
    margin-right: 10px;
}

.cat-detail .segment-tabs .tab-ia:before {
    font-family: "Font Awesome 6 Pro";
    content: "\e1ec";
    font-weight: 300;
    font-size: 22px;
    margin-right: 10px;
}

.cat-detail .segment-tabs .nav-tabs {
    border-bottom-color: transparent !important;
}

.cat-detail .segment-group.active .tab-pane.active {
    display: block !important;
}

.cat-detail .tab-content {
    font-size: 14px;
    font-weight: 400;
}

.cat-detail .tab-content .tab-content-row {
    display: flex;
    width: 100%;
    background-color: #fff;
    padding: 4px 8px;
    gap: 15px;
}

.cat-detail .tab-content .tab-content-column {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: justify;
}

.cat-detail .tab-content .tab-content-row .tab-content-column-1 {
    display: flex;
}

.cat-detail .tab-content .tab-content-row .tab-content-column-2 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
}

.cat-detail .tab-content .tab-content-row .label-percentage {
    font-weight: 600;
}

.cat-detail .tab-content .tab-content-row .label-percentage.one {
    color: #00C48C;
    background-color: #DDF5EE;
}

.cat-detail .tab-content .tab-content-row .label-percentage.two {
    color: #8950FC;
    background-color: #EEE5FF;
}

.cat-detail .tab-content .tab-content-row .label-percentage.three {
    color: #FD8C04;
    background-color: #FFE1A6;
}

.cat-detail .tab-content .tab-content-row .label-percentage {
    font-weight: 600;
}

.cat-detail .tab-content .tab-content-row .label-percentage {
    font-weight: 600;
}

.cat-detail .tab-content .tab-content-row .suggestion {
    color: #7E8299;
    padding-left: 8px;
    font-size: 14px;
    font-weight: 400;
}

.cat-detail .radio.radio-success > input:checked ~ span:after {
    width: 10px;
    height: 10px;
}

.cat-detail .radio.radio-success > input:checked ~ span {
    background-color: #2E8F96;
}

.cat-detail .segment-group .translated-segment-editor.error:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f071";
    font-weight: 300;
    font-size: 20px;
    margin-right: 5px;
    color: #D73C37;
    vertical-align: baseline;
}

.cat-detail .segment-group .translated-segment-editor.error {
    border-color: #D73C37 !important;
}

#cat-error-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #FFDFE3;
    padding: 8px 24px;
    font-size: 14px;
    font-weight: 400;
    gap: 16px;
    height: 45px;
    margin-top: -25px;
    margin-bottom: 25px;
    align-items: center;
}

#cat-error-tags > span:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f071";
    font-weight: 300;
    font-size: 20px;
    margin-right: 20px;
    color: #FF647C;
    vertical-align: middle;
}

#cat-error-tags > a {
    text-decoration: none;
    color: #464E5F !important;
}

#cat-error-tags > a:hover {
    text-decoration: none;
    color: #464E5F !important;
}

.cat-detail .tab-content .ia-tab-icon:before {
    font-family: "Font Awesome 6 Pro";
    content: "\e1ec";
    font-weight: 300;
    font-size: 32px;
    color: #ADD0D4;
    display: inline-block;
    transform: rotate(15deg);
}

.cat-detail .tabs-ia .tab-content textarea {
    resize: none;
    overflow: auto;
    border:  none;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    width: 100%;
    height: 100%;
}

.cat-detail .tabs-ia .tab-content textarea:hover,
.cat-detail .tabs-ia .tab-content textarea:focus {
    outline: none;
}

.cat-detail .warning-notice {
    background-color: #FFEAC0;
    padding: 8px;
    border-radius: 4px;
    width: 100%;
}

.cat-detail .warning-notice .icon-warning:before {
    font-family: "Font Awesome 6 Pro";
    content: "\f06a";
    font-weight: 300;
    font-size: 16px;
    color: #FFB92E;
    display: inline-block;
}

.cat-detail .tabs-ia .tab-content .tab-content-row {
    background-color: transparent !important;
}

.form-group .col-form-label {
    font-weight: 400;
    font-size: 14px;
}

.form-group .col-form-label.required:after {
    content: " *";
    color: red;
}