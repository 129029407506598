header:before {
    content: "";
    background-color: #1e1e2d !important;
}

header #logo img {
    background-color: #1e1e2d !important;
}

body {
    font-family: 'Titillium' !important;
    font-style: normal !important;
    color: #464E5F !important;
    background: #EEF0F8 !important;
}

.opblock-summary-method {
    border-top-left-radius: 0.42rem !important;
    border-bottom-left-radius: 0.42rem !important;
}

#swagger-ui.api-platform .information-container.wrapper {
    border-bottom: none !important;
    border-radius: 0.42rem !important;
}

.swagger-ui .scheme-container {
    border-radius: 0.42rem !important;
    box-shadow: none !important;
}

.swagger-ui .info li, .swagger-ui .info p, .swagger-ui .info table {
    font-family: 'Titillium' !important;
    color: #464E5F !important;
}

.swagger-ui .model {
    font-family: 'Titillium' !important;
    color: #464E5F !important;
}

.swagger-ui .model-title {
    font-family: 'Titillium' !important;
    color: #464E5F !important;
}

.model-container {
    background: #fff !important;
    border-radius: 0.42rem !important;
}

.opblock {
    border-radius: 0.42rem !important;
    box-shadow: none  !important;
}